<template>
  <div class="page-listings calendar-page mobile-top">
    <section>
      <div class="iframe-wrapper">
        <iframe
          onload="resizeIframe(this)"
          src="https://outlook.office365.com/owa/calendar/cf9f746ab2e44a04bc0ce862925336f5@spacesaver.com/08816152b3ac401c8b6ca10d0ffd6b5c7457858485020634844/calendar.html"
          title="Spacesaver Calendar"
          frameborder="0"
          style="min-height:98vh"
        ></iframe>
      </div>
    </section>

    <!--
    <section class="py-5  bg9 bs-shadow mobile-top">
      <div class="container">
        <h1>Calendar</h1>
        <div class="search-btns">
          <div class="left-side">
            <div class="dates" id="dates">
              <datepicker
                format="MMM dd yyyy"
                placeholder="From Date"
                v-model="startDate"
                wrapperClass="pickthedate"
              ></datepicker>
              <datepicker
                format="MMM dd yyyy"
                placeholder="To Date"
                v-model="endDate"
                wrapperClass="pickthedate"
              ></datepicker>
            </div>
          </div>

          <button
            v-if="searchValue || startDate || endDate || acFilter"
            @click="clearSearch"
            class="clear"
          >
            <span class="material-icons">find_replace</span>
            {{ " " }} Clear
          </button>
          <button v-else disabled class="clear">
            <span class="material-icons">find_replace</span>
            {{ " " }} Clear
          </button>

          <button
            v-if="
							(searchValue || startDate || endDate || acFilter) &&
								!searchStarted
						"
            @click.prevent="searchQuery"
            class="search"
          >
            <span class="material-icons">search</span>
            {{ " " }} Search
          </button>
          <button v-else class="search" disabled>
            <span class="material-icons">search</span>
            {{ " " }} Search
          </button>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div class="listing mt-3">
            <iframe
              onload="resizeIframe(this)"
              src="https://outlook.office365.com/owa/calendar/427dbfb34bb8458bbbf95221102f51b3@spacesaver.com/c4d701c866f24e0f9f29d0a832fba85011720271240133829745/calendar.html"
              title="Spacesaver Calendar"
              frameborder="0"
              style="min-height:98vh"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import Datepicker from "vuejs-datepicker";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "Warranty",
  components: {
    AtomSpinner,
    Avatar,
    Datepicker,
  },
  data() {
    return {
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      backend: process.env.VUE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedListings: [],
      searchRender: false,
      startDate: null,
      endDate: null,
      searchStarted: false,
      acs: [],
      warranties: [],
      selectedCat: "Show All",
      acFilter: null,
      searching: false,
      loading: true,
      countOfPage: 25,
      currPage: 1,
      noRGAFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.$store.commit("setActivePage", "tools");
    },
    resizeIframe: function (iframe) {
      iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.warranty {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.warranty-bottom {
  align-items: flex-end;
}
.warranty-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.iframe-wrapper {
  background: #f3f2f1;
  /*padding-top: 50px;*/
}
iframe {
  width: 100%;
  margin: 0;
  padding: 0;
}
.bottom-border {
  min-height: 50px;
  background: #f3f2f1;
}
.calendar-page {
  background: #f3f2f1;
}
html[dir] .OBrYajlsckthZj2jJ7_X6,
html[dir] .OBrYajlsckthZj2jJ7_X6:active,
html[dir] .OBrYajlsckthZj2jJ7_X6:hover {
  background-color: var(--themeLighter);
  background-color: #429da8 !important;
}
.wLZVBi_RWIoVQSybtp_ZX {
  font-weight: 600;
  color: var(--themeDarkAlt);
  color: #429da8 !important;
}
</style>
